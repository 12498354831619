<template>
    <el-table :default-sort="preSelectedSort" :data="invoicesData" @sort-change="sortChange" v-loading="$waiting.is('loading')" @selection-change="handleSelectionChange" size="mini">
        <el-table-column prop="paymentStatus" label="Status" align="center" :sortable="false" width="90" />
        <el-table-column prop="clientName" label="Klient" :sortable="false" show-overflow-tooltip />
        <!-- <el-table-column prop="id" label="id" :sortable="false" width="70" /> -->
        <el-table-column prop="customerName" label="Kund" :sortable="false" show-overflow-tooltip />
        <el-table-column prop="invoiceNumber" label="Fak nr" :sortable="false" width="100" />
        <el-table-column prop="ocrNumber" label="OCR" :sortable="false" width="110" />
        <el-table-column prop="paymentAmount" label="Kvar att betala" align="right" :sortable="false" width="150">
            <template slot-scope="scope"> {{ scope.row.paymentRest | swedishNumberFormat }} {{ scope.row.currency }} </template>
        </el-table-column>
        <el-table-column prop="grossAmount" align="right" label="Bruttobelopp" :sortable="false" width="140">
            <template slot-scope="scope"> {{ scope.row.grossAmount | swedishNumberFormat }} {{ scope.row.currency }} </template>
        </el-table-column>
        <el-table-column prop="date" label="Datum" :sortable="false" width="100">
            <template slot-scope="scope">
                <p>
                    {{ formatDate(scope.row.date) }}
                </p>
            </template>
        </el-table-column>
        <el-table-column prop="dueDate" label="Förfallodatum" :sortable="false" width="140">
            <template slot-scope="scope">
                <p>
                    {{ formatDate(scope.row.dueDate) }}
                </p>
            </template>
        </el-table-column>
        <el-table-column width="150" align="right">
            <template slot-scope="scope">
                <el-popover
                    v-if="scope.row.comment != null"
                    popper-class="bg-company-blue text-white"
                    title="Notera"
                    trigger="hover"
                    placement="left"
                    :content="scope.row.comment"
                    :visible-arrow="false"
                    width="500"
                >
                    <el-button type="primary" class="p-1 mr-2" slot="reference">
                        <i class="fa-solid fa-comment" />
                    </el-button>
                </el-popover>

                <el-tooltip class="item" effect="light" content="Matcha fakturan" placement="top">
                    <el-button type="" class="p-1" @click="emitMatch(scope.row.id)">
                        <i class="fa-solid fa-link text-base" style="color: #317e0d;" />
                    </el-button>
                </el-tooltip>

                <el-tooltip class="item" effect="light" content="Details" placement="top">
                    <router-link :to="'invoices/' + scope.row.id">
                        <el-button type="" class="p-1">
                            <i class="far fa-magnifying-glass text-base" style="color: #1235a5;" />
                        </el-button>
                    </router-link>
                </el-tooltip>

            </template>
        </el-table-column>
    </el-table>
</template>
<script>
import queryString from "query-string";
import moment from "moment";
export default {
    props: {
        invoicesData: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            sortBy: null,
            sortByType: null,
        };
    },

    created() {
        this.assignInitValues();
    },

    computed: {
        preSelectedSort() {
            if (!this.sortBy) return {};
            return {
                prop: this.sortBy,
                order: this.sortByType,
            };
        },
    },

    methods: {
        assignInitValues() {
            const { sortBy, sortByType } = queryString.parse(location.search);
            this.sortBy = sortBy;
            this.sortByType = sortByType;
        },

        formatDate(date) {
            return moment(date).format("YYYY-MM-DD");
        },

        sortChange(event) {
            const query = queryString.parse(window.location.search, { sort: false });
            query.sortBy = event.order ? event.prop : undefined;
            query.sortByType = event.order || undefined;
            this.$router.replace({ query }).catch(() => {});
            this.$emit("sortTypeChanged");
        },

        handleSelectionChange() {},

        emitMatch(id) {
            this.$emit("matchInvoice", id);
        },
    },
};
</script>
<style>
.el-popover__title {
    color: #fff;
}
</style>
